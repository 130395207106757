import { create } from 'zustand'
import { n } from '@fullcalendar/core/internal-common'
import { IChatType } from 'api/ws/ws'
import { IItemMessageOfTextChat } from './chat-messages'
import LocalPoliceIcon from '@mui/icons-material/LocalPolice'
import { amber, deepOrange, grey, lightBlue, lime } from '@mui/material/colors'
import StarsIcon from '@mui/icons-material/Stars'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import VisibilityIcon from '@mui/icons-material/Visibility'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import { ROLES } from './user-profile'

export enum DOING_OF_TEXT_CHAT {
    WRITE_TEXT_MESSAGE = 'WRITE_TEXT_MESSAGE',
    WRITE_AUDIO_MESSAGE = 'WRITE_AUDIO_MESSAGE'
}

export type IGroupMember = { groupId: number; memberId: number; confirmInvite: number; isUserBanned: number; isUserLeft: number; timestamp: string | null | {} }

export type IItemChatGroupOfListContacts = {
    id: number
    type: IChatType.GROUP
    title: string
    description: string | null
    modeConnection: 'free' | 'invite'
    chatRoleId: CHAT_ROLES
    ownerUserId: number
    // memberIds: number[],
    members: IGroupMember[]
    bgColorAvatar: string | null
    urlAvatar: string | null
    timestamp: string | null
    isOnline: boolean
    isMuted: boolean
    isPined: boolean
    lastMessage: IItemMessageOfTextChat
    lastMessageViewed: boolean
    unreadCount: number
    unreadMessageIds: number[] | null
    timestampEmailSend: Date
}

export enum CHAT_ROLES {
    ALL = 1, // Все участники
    WATCHER = 2, // Наблюдатель
    USER = 3 // Пользователь
}

export const CHAT_ROLE_TITLE = {
    [CHAT_ROLES.ALL]: 'Все участники',
    [CHAT_ROLES.WATCHER]: 'Наблюдатели',
    [CHAT_ROLES.USER]: 'Пользователи'
}

export const CHAT_ROLE_DESCRIPTION = {
    [CHAT_ROLES.ALL]: 'Все участники будут членами группы (текущие и будущие)',
    [CHAT_ROLES.WATCHER]: 'Все Наблюдатели будут участниками (текущие и будущие)',
    [CHAT_ROLES.USER]: 'Все Пользователи будут участниками (текущие и будущие)'
}

type IStore = {
    chatGroupsOfListContacts: IItemChatGroupOfListContacts[]
    setChatGroupsOfListContacts(userContacts: IStore['chatGroupsOfListContacts']): void
}

export const useChatGroupsOfListContactsStore = create<IStore>(set => ({
    chatGroupsOfListContacts: [],
    setChatGroupsOfListContacts: data => set(state => ({ chatGroupsOfListContacts: data }))
}))
