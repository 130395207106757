import { create } from "zustand";

export enum SHOP_EXTENSION {
    SCREEN_CONTROL = 1, // Цифровая вывеска
    GOOGLE_MEET = 2,
    CALENDAR = 3,
    DISC = 4,
    VPN_TOUCH = 6,
    MEETING_CALL_AGORA = 11,
    ZOOM = 12,
    ZEGOCLOUD = 15,
    LIVEKIT = 16,
    JITSU = 17,
}


export interface IShopExtension {
    id: SHOP_EXTENSION;
    image: string;
    title: string;
    type: ITypeItemShop;
    status: string;
    shortDescription: string;
    description: string;
    requirement: string;
    installed: boolean;
    disabled: boolean;
    // data: null;
}

export interface IShopExtensionOthers extends IShopExtension {
    id: SHOP_EXTENSION,
    data: null
}

export interface IShopExtensionAgora extends IShopExtension {
    id: SHOP_EXTENSION.MEETING_CALL_AGORA,
    data: {agoraAppId: string, agoraAppCertificate: string}
}

export interface IShopExtensionZegoCloud extends IShopExtension {
    id: SHOP_EXTENSION.ZEGOCLOUD,
    data: {zegoCloudAppId: number, zegoCloudAppSecret: string}
}


type IStore = {
    filters: {
        isModule: boolean,
        isExtension: boolean,
        isInstalled: boolean,
        isDisabled: boolean,
    }
    shopExtensions: Array<IShopExtensionOthers | IShopExtensionAgora | IShopExtensionZegoCloud>;
    setShopExtension(messages: IStore['shopExtensions']): void
    setFilters(filters: Partial<IStore["filters"]>): void
}

export enum ITypeItemShop {
    EXTENSION = 1,
    MODULE = 2,
}

export const useShopExtensionsStore = create<IStore>(set => ({
    filters: {
        isModule: true,
        isExtension: false,
        isInstalled: false,
        isDisabled: false,
    },
    shopExtensions: [],
    setShopExtension: shopExtensions => set(state => ({ shopExtensions })),
    setFilters: filters => set(state => ({ filters: {...state.filters, ...filters} })),
}))