import { ROLES, ROLE_OPTIONS } from 'store/user-profile'
import { isEmpty, isStrongPassword } from 'validator'

// копирование переданной строки в память
export async function copyToClipboard(textToCopy: string) {
    if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(textToCopy)
    } else {
        const textArea = document.createElement('textarea')
        textArea.value = textToCopy

        textArea.style.position = 'absolute'
        textArea.style.left = '-999999px'

        document.body.prepend(textArea)
        textArea.select()

        try {
            document.execCommand('copy')
        } catch (error) {
            console.error(error)
        } finally {
            textArea.remove()
        }
    }
}

export function getFullUrlImage(str: string | null | undefined): string {
    try {
        if (!str) return ''
        const url = new URL(str)
        return str
    } catch (error) {
        return `${process.env.REACT_APP_BACKEND}/${str}`
    }
}

// преобразование файла в формат base64
export function encodeImageFileAsURL(e: React.ChangeEvent<HTMLInputElement>) {
    return new Promise<string>((resolve, reject) => {
        // @ts-ignore
        const file = e.currentTarget.files[0]
        const reader = new FileReader()
        reader.onloadend = function () {
            console.log('RESULT', reader.result)
            resolve(reader.result as string)
        }
        reader.onerror = function (error) {
            console.log('ERROR', error)
            reject({ error })
        }
        reader.readAsDataURL(file)
    })
}

// вернуть из строки массив найденных ссылок
export function getUrlFromString(str: string = ''): string[] {
    return `${str}`.match(/\bhttps?:\/\/\S+/gi) || []
}

// проверка логина
export function checkLogin(login: string) {
    if (isEmpty(login)) {
        return 'Обязательное поле'
    } else if (login.length < 3) {
        return 'Слишком короткий логин'
    } else if (login.length > 20) {
        return 'Слишком длинный логин'
    } else if (/^\d/.test(login)) {
        return 'Логин не должен начинаться с цифры'
    } else if (/\s/.test(login)) {
        return 'Логин не должен содержать пробелы'
    } else if (!/^[a-zA-Z0-9_.-]+$/.test(login)) {
        return 'Логин может содержать цифры, латинские символы в верхнем и нижнем регистре, точку, дефис или подчеркивание'
    }
    return undefined
}

// проверка пароля
export function checkPassword(password: string) {
    if (!password) {
        return 'Обязательное поле'
    } else if (password.length < 6) {
        return 'Слишком короткий пароль'
    } else if (
        !isStrongPassword(password, {
            minLength: 6,
            minSymbols: 0
        })
    ) {
        return 'Пароль должен содержать минимум 1 лат. символ в верхнем, 1 лат. символ в нижнем регистре и 1 число'
    } else if (password.length > 12) {
        return 'Слишком длинный пароль'
    }
    return undefined
}

export function checkRole(roleId: number) {
    return !!ROLES[roleId]
}

export function getRandomRange(min: number, max: number) {
    return Math.round(Math.random() * (max - min) + min)
}

export function randomStringByLength(len: number) {
    let result = ''
    if (result) return result
    var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
        maxPos = chars.length,
        i
    len = len || 5
    for (i = 0; i < len; i++) {
        result += chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return result
}